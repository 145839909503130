import styled from "styled-components";
import { devices } from "../../../utils/constants";


export const AreaText = styled.div`
 display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.5rem;
padding-left:3rem;
padding-top:2.625rem;
padding-bottom:2.625rem;
padding-right:0;
box-sizing: border-box;
.flag{
  font-family: Lexend;
}

`;

export const ButtonSlide = styled.button<{right?:boolean;left?:boolean}>`
  width:2rem;
  height:2rem;
  box-sizing: border-box;
  background-color: #1A1198;
  border-radius: 100px;
  position: absolute;
  top:50%;
  transform:translateZ(50%);
  right:24px;
  @media (max-width: 768px) {
    display:none;
  }
`


export const Container = styled.div`
  display: flex;
  height: 32rem;
  padding: 1.25rem;
  margin-top:5rem;
  box-sizing: border-box;
  position:relative;
  width:100%;
  background-color: #fdfdfd;
  >div{

    width:100%;
  }
 
  @media (max-width: 768px) {
    flex-direction: column;
    padding:0;
    height:auto
  }

`;

export const Content = styled.div`
  width:100%;
  height: 30rem;
  border-radius: 0.25rem;
  background-color: #090633;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: flex-end;
  padding-left: 7%;
  
 
  @media (max-width: 768px) {
    flex-direction: column;
    padding-left: 0;
    height:auto
  }


  @media (max-width: 414px) {
    width:100%!important;
    height:100%
  }
`;


export const BackgroundImage = styled.img`
  width: 70% !important;
  object-position: top;
  object-fit: cover;
  height: 100%;

  @media (max-width: 768px) {
    width:100%!important;
    height:22.5rem
  }


  @media (max-width: 414px) {
    width:100%!important;
    height:17.5rem
  }
`;

export const Context = styled.div`
  display: flex;
  position:absolute;
  flex-direction: column;
  left:5%;
  width: 100%;
  max-width: 43.25rem;

  .flag{
    background-color: #EDF5FF;
    color:#114C98;
    font-weight: 700;
    padding:0.5rem 1rem;
    width:fit-content;
    font-size: 10px;
  }


  @media (max-width: 768px) {
    position: static;
    top: 0;
    max-width: 100%;
    max-width: 100%;
  }


  @media (max-width: 414px) {
    width:100%!important;
    flex:1;
  }

`;

export const AreaTitle = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 30.5rem;

  @media only screen and (${devices.lg}) {
    max-width: 34rem;
  }
`;

export const Title = styled.h1`
  color: rgba(0, 0, 0, 0.6);
  font-family: Lexend;
  font-size: 2.5rem;
  text-align: start;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  margin: 0;

  @media only screen and (${devices.sm}) {
    font-size: 2rem;
  }
`;

export const TextBlue = styled.span`
  color: #2c1dfe;
`;

export const AreaButtons = styled.div`
  display: flex;
  flex-direction: row;

  @media only screen and (max-width: 516px) {
    flex-direction: column;
  }
`;

export const AreaImages = styled.div`
  display: inline-flex;
  max-height: 39.44325rem;
  height: 100%;
  align-items: flex-start;
  gap: 1.03388rem;
  flex-shrink: 0;
  position: relative;

  @media only screen and (max-width: 1045px) {
    max-height: 36.44325rem;
  }

  @media only screen and (${devices.lg}) {
    margin-top: 3.25rem;
    margin-bottom: 1rem;
    max-height: 29.75rem;
  }

  @media only screen and (${devices.xs}) {
    max-height: 21.6875rem;
  }
`;

export const AreaImageOne = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 0.51694rem;
  align-self: stretch;
`;

export const AreaImageTwo = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 0.51694rem;
  align-self: stretch;
`;

export const Img = styled.img`
  max-width: 100%;
  width: 14.73306rem;
  height: 29.46613rem;

  @media only screen and (max-width: 1045px) {
    width: 12.73306rem;
    height: 27.46613rem;
  }

  @media only screen and (${devices.lg}) {
    width: 12.5rem;
    height: 25rem;
  }

  @media only screen and (${devices.xs}) {
    width: 9rem;
    height: 18rem;
  }

  @media only screen and (max-width: 376px) {
    width: 9rem;
    height: 16rem;
  }

  @media only screen and (max-width: 346px) {
    width: 9rem;
    height: 14rem;
  }

  @media only screen and (max-width: 321px) {
    width: 9rem;
    height: 13rem;
  }
`;

export const RectangleHomeOne = styled.div`
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 0.25rem;
  background: #5e1198;
  box-shadow: 0px 4px 8px 0px rgba(5, 3, 26, 0.1),
    0px 0.5px 1px 0.5px rgba(5, 3, 26, 0.03);
  position: absolute;
  top: 16%;
  left: 6%;

  @media only screen and (${devices.lg}) {
    top: 10%;
    left: 4%;
  }

  @media only screen and (${devices.xs}) {
    width: 1.8235rem;
    height: 1.8235rem;
    left: 9%;
  }
`;

export const RectangleHomeTwo = styled.div`
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 0.25rem;
  background: #1a1198;
  box-shadow: 0px 4px 8px 0px rgba(5, 3, 26, 0.1),
    0px 0.5px 1px 0.5px rgba(5, 3, 26, 0.03);
  position: absolute;
  bottom: 12%;
  right: 21%;

  @media only screen and (${devices.lg}) {
    bottom: 3%;
    right: 15%;
  }

  @media only screen and (${devices.xs}) {
    width: 1.8235rem;
    height: 1.8235rem;
    right: 6%;
  }
`;

export const RectangleHomeTextOne = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 8.625rem;
  height: 2.5rem;
  border-radius: 0.25rem;
  background: #9c1dfe;
  box-shadow: 0px 4px 8px 0px rgba(5, 3, 26, 0.1),
    0px 0.5px 1px 0.5px rgba(5, 3, 26, 0.03);
  position: absolute;
  bottom: 23%;
  left: -9%;

  span {
    color: #fff;
    text-align: center;
    leading-trim: both;
    text-edge: cap;
    font-family: Lexend;
    font-size: 0.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
  }

  @media only screen and (${devices.lg}) {
    bottom: 7%;
    left: -12%;
  }

  @media only screen and (${devices.xs}) {
    width: 6.29106rem;
    height: 1.8235rem;
    bottom: 5%;
    left: 20%;
  }
`;

export const RectangleHomeTextTwo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 8.625rem;
  height: 2.5rem;
  border-radius: 0.25rem;
  background: #2c1dfe;
  box-shadow: 0px 4px 8px 0px rgba(5, 3, 26, 0.1),
    0px 0.5px 1px 0.5px rgba(5, 3, 26, 0.03);
  position: absolute;
  top: 6%;
  left: 37%;

  span {
    color: #fff;
    text-align: center;
    leading-trim: both;
    text-edge: cap;
    font-family: Lexend;
    font-size: 0.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
  }

  @media only screen and (${devices.lg}) {
    top: 4%;
    left: 30%;
  }

  @media only screen and (${devices.xs}) {
    width: 6.29106rem;
    height: 1.8235rem;
    top: 2%;
    left: 42%;
  }
`;

export const AreaDescription = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #FDFDFD;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;

  
  @media (max-width: 414px) {
   height:100%;
  }

`;

export const Footer = styled.div<{color:string}>`
  width: 100%;
  display: flex;
  align-items: center;
  background-color:  ${({ color }) => color};
  border-bottom-left-radius: 0.25rem;
  box-sizing: border-box;
  border-bottom-right-radius: 0.25rem;
  padding:2rem 3.625rem;

  >div{
    display:flex;
    column-gap:3rem;
    row-gap:1rem;
    flex-wrap: wrap;
  }

  @media only screen and (max-width: 960px) {
    border-radius: 0;
    justify-content: flex-start;
  }
`;

export const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  column-gap: 0.5rem;
  row-gap: 5rem;
  margin-top: 5rem;
`;


export const Description = styled.p`
  color: rgba(0, 0, 0, 0.6);
  font-family: Lexend;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 300;
  line-height: 120%;
  margin: 0;

  @media only screen and (${devices.sm}) {
    font-size: 1rem;
  }
`;