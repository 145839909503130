import Container from "./styles"
import img1 from "../../../assets/images/models/01.png"
import img2 from "../../../assets/images/models/02.png"
import img3 from "../../../assets/images/models/03.png"
import img4 from "../../../assets/images/models/04.png"
import img5 from "../../../assets/images/models/05.png"
import img6 from "../../../assets/images/models/06.png"
import img7 from "../../../assets/images/models/07.png"
import img8 from "../../../assets/images/models/08.png"
import img9 from "../../../assets/images/models/09.png"
import img10 from "../../../assets/images/models/10.png"
import img11 from "../../../assets/images/models/11.png"
import img12 from "../../../assets/images/models/12.png"
import img13 from "../../../assets/images/models/13.png"
import img14 from "../../../assets/images/models/14.png"
import img15 from "../../../assets/images/models/15.png"
import img16 from "../../../assets/images/models/16.png"
import waves from "../../../assets/icons/wave.svg"

const Models = () =>{


    return<Container>
       <div>
       <h2>Conheça modelos que fazem parte da Oceano</h2>

<div>
    <img src={img1} alt="Modelo 1"/>
    <img src={img2} alt="Modelo 2"/>
    <img src={img3} alt="Modelo 3"/>
    <img src={img4} alt="Modelo 4"/>
    <img src={img5} alt="Modelo 5"/>
    <img src={img6} alt="Modelo 6"/>
    <img src={img7} alt="Modelo 7"/>
    <img src={img8} alt="Modelo 8"/>
    <img src={img9} alt="Modelo 9"/>
    <img src={img10} alt="Modelo 10"/>
    <img src={img11} alt="Modelo 11"/>
    <img src={img12} alt="Modelo 12"/>
    <img src={img13} alt="Modelo 13"/>
    <img src={img14} alt="Modelo 14"/>
    <img src={img15} alt="Modelo 15"/>
    <img src={img16} alt="Modelo 16"/>
    <div className="container_more">
    <div className="wave">
    <img src={waves} alt="Modelo 16"/>
    </div>
    <p>Descubra outros talentos Oceano na nossa Plataforma</p> 
    </div>
</div>
       </div>
    </Container>
}

export default Models