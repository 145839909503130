import { Link, useNavigate } from "react-router-dom";
import TextButton from "../../../components/TextButton";
import banner1 from "../../../assets/homeBanner/banner1.png";
import banner2 from "../../../assets/homeBanner/banner2.png";
import banner3 from "../../../assets/homeBanner/banner3.png";
import banner4 from "../../../assets/homeBanner/banner4.png";
import banner5 from "../../../assets/homeBanner/banner5.png";
import { faArrowRight } from "@fortawesome/pro-regular-svg-icons";
import {
  Content,
  Context,
  Title,
  AreaText,
  AreaDescription,
  Footer,
  BackgroundImage,
  Container,
  ButtonSlide,
} from "./styles";
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const slides = [{
  title: "Anuncie no Rádio e na TV e ganhe visibilidade ",
  img: banner1,
  color: "#2C1DFE",
  navigations: [{
    label: "ANUNCIAR AGORA",
    page: "/media",
    type:undefined
  }]
}, {
  title: "Casting exclusivo de personalidades",
  img: banner5,
  color: "#9C1DFE",
  navigations: [{
    label: "PROTIFÓLIO DE PERSONALIDADES",
    page: "/personalities",
    type:undefined
  }]
}, {
  title: "Contrate modelos e influencers para sua campanha",
  img: banner2,
  color: "#FE564A",
  navigations: [{
    label: "CONTRATAR TALENTOS",
    page: "/service-talent",
    type:undefined
  },
  {
    label: "QUERO SER UM TALENTO",
    page: "/talent",
    type:undefined
  }]
}, {
  title: "Seja cliente e participe do nosso Programa de Cashback",
  img: banner3,
  color: "#4BAA82",
  navigations: [{
    label: "SOU MARCA OU EMPRESA",
    page: "/our-services",
    type:undefined
  },
  {
    label: "QUERO SER UM TALENTO",
    page: "/talent",
    type:undefined
  }]
}, {
  title: "Plataforma de Cursos -Fomentando Talentos",
  img: banner4,
  color: "#C477FE", navigations: [{
    label: "IR PARA CURSOS",
    page: "https://cursos.plataformaoceano.com.br",
    type:"_blank"
  }]
}]

const Header = () => {
  const navigate = useNavigate();

  const handleNavigate = (route: string) => {
    navigate(route);
  };

  return (
    <Container>
      <div>
        <Carousel
          autoPlay
          showStatus={false}
          infiniteLoop
          interval={8000}
          showThumbs={false}
          showIndicators={false}
          swipeable={false}

          renderArrowNext={(onClickHandler, hasPrev, label) => (
            <ButtonSlide type="button" right onClick={onClickHandler}>
              <FontAwesomeIcon
                style={{ width: "12px", height: "12px" }}
                color="#fff"
                title="close"
                icon={faArrowRight}
              />
            </ButtonSlide>
          )}
          renderArrowPrev={() => (
            <></>
          )}

          showArrows >
          {slides.map((slide, index) => (
            <Content>
              <Context>
                <AreaDescription>


                  <AreaText>
                    <div className="flag">
                      TUDO EM UM SÓ LUGAR
                    </div>
                    <Title>{slide.title} </Title>
                  </AreaText>
                </AreaDescription>
                <Footer color={slide.color || ""}>
                  <div>
                    {slide.navigations.map((navigation) => (
                      <Link to={navigation.page} target={navigation?.type} >
                        <TextButton
                          text={navigation.label}
                          icon={<svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7 0.25C10.8555 0.25 14 3.39453 14 7.25C14 11.1328 10.8555 14.25 7 14.25C3.11719 14.25 0 11.1328 0 7.25C0 3.39453 3.11719 0.25 7 0.25ZM11.1016 7.87891C11.3203 7.66016 11.375 7.38672 11.375 7.25C11.375 7.14062 11.3203 6.86719 11.0742 6.62109L8.25781 3.80469C7.92969 3.44922 7.35547 3.44922 7.02734 3.80469C6.67188 4.13281 6.67188 4.70703 7.02734 5.03516L8.36719 6.375H3.5C3.00781 6.375 2.625 6.78516 2.625 7.25C2.625 7.74219 3.00781 8.125 3.5 8.125H8.36719L7.05469 9.46484C6.69922 9.79297 6.69922 10.3672 7.05469 10.6953C7.38281 11.0508 7.95703 11.0508 8.28516 10.6953L11.1016 7.87891Z" fill="#EEEDFF" />
                          </svg>}
                          color="#fff"
                        />
                      </Link>

                    ))}
                  </div>
                </Footer>
              </Context>
              <BackgroundImage src={slide.img} />
            </Content>
          ))}

        </Carousel>

      </div>
    </Container>
  );
};

export default Header;
