import React from "react";
import Header from "./Header";
import Solutions from "./Solutions";
import ScrollableText from "./ScrollableText";
import SuccessfulCampaigns from "./SuccessfulCampaigns";
import Opportunities from "./Opportunities";
import ForTalents from "./ForTalents";
import HowToFindOpportunities from "./HowToFindOpportunities";
import DownloadApp from "./DownloadApp";
import SocialMedia from "./SocialMedia";
import NavigationHome from "../../components/Navigation/navigationHome";
import Models from "./Models";

const Home = () => (
  <>
    <Header />
    <NavigationHome />
    <Solutions />
    <ScrollableText />
    <SuccessfulCampaigns />
    <Opportunities />
    <ForTalents />
    <HowToFindOpportunities />
    <Models/>
    <DownloadApp />
    <SocialMedia />
  </>
);

export default Home;
